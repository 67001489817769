import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: block;\n  color: ",
        ";\n  font-weight: 600;\n  font-size: 16px;\n  &:hover {\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var LinkLabelFooter = styled.div.withConfig({
    componentId: "sc-937ab9e5-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.colors.textSubtle;
}, function(param) {
    var theme = param.theme;
    return theme.colors.textSubtle;
});
