import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  bottom: 0px;\n  width: 100%;\n  padding: 0 24px 8px 8px;\n  background: #3bbbbd;\n  z-index: 20;\n\n  & > .nav-menu-content {\n    height: 70px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    overflow-x: auto;\n    /* overflow-y: hidden; */\n\n    -ms-overflow-style: none; /* IE and Edge */\n    scrollbar-width: none; /* Firefox */\n    &::-webkit-scrollbar {\n      display: none;\n    }\n\n    & > div:not(.right-arrow-menu) {\n      margin-right: 20px;\n    }\n  }\n\n  .right-arrow-menu {\n    position: absolute;\n    top: 50%;\n    right: 0px;\n    transform: translateY(-50%);\n    div {\n      border-width: 8px;\n      border-style: solid;\n      border-color: transparent transparent transparent #ffffff;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { Flex } from "../Box";
var StyledBottomNav = styled(Flex).withConfig({
    componentId: "sc-faffb6cb-0"
})(_templateObject());
export default StyledBottomNav;
