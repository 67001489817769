export const setCookie = (name: any, value: any, expried: any) => {
  let expires = ''
  if (expried) {
    const date = new Date(expried)
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${(value || '') + expires}; path=/`
}

export const getCookie = (name: any) => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export const eraseCookie = (name: any) => {
  document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`
}
