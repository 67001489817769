import { createAction } from '@reduxjs/toolkit'
import { UserStructOutput } from 'config/abi/types/StakingAbiNew'
import { StakingItemType, StakingHistory } from './types'

export const setStakingListNew = createAction<{ stakingList: StakingItemType[] | null | undefined }>(
  'stakingNew/setStakingList',
)

export const setTotalStakedNew = createAction<number | null | undefined>('stakingNew/setTotalStaked')

export const setUserInfo = createAction<UserStructOutput | null | undefined>('stakingNew/setUserInfo')

export const setTotalCommission = createAction<number | null | undefined>('stakingNew/setTotalCommission')

export const setStakingHistoryNew = createAction<StakingHistory[] | null | undefined>('stakingNew/setStakingHistory')
