import { createReducer } from '@reduxjs/toolkit'
import { UserStructOutput } from 'config/abi/types/StakingAbiNew'
import { setStakingListNew, setTotalStakedNew, setStakingHistoryNew, setTotalCommission, setUserInfo } from './actions'
import { StakingHistory, StakingItemType } from './types'

export interface InvestState {
  stakingList: StakingItemType[] | null | undefined
  totalStaked: number | null | undefined
  totalCommission: number | null | undefined
  userInfo: UserStructOutput | null | undefined
  stakingHistory: StakingHistory[] | null | undefined
}

export const initialState: InvestState = {
  stakingList: undefined,
  totalStaked: undefined,
  stakingHistory: undefined,
  totalCommission: undefined,
  userInfo: undefined,
}

export default createReducer<InvestState>(initialState, (builder) =>
  builder
    .addCase(setStakingListNew, (state, { payload }) => {
      state.stakingList = payload.stakingList
    })
    .addCase(setTotalStakedNew, (state, { payload }) => {
      state.totalStaked = payload
    })
    .addCase(setTotalCommission, (state, { payload }) => {
      state.totalCommission = payload
    })
    .addCase(setUserInfo, (state, { payload }) => {
      state.userInfo = payload
    })
    .addCase(setStakingHistoryNew, (state, { payload }) => {
      state.stakingHistory = payload
    }),
)
