import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  ",
        ";\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { flexbox } from "styled-system";
import Box from "./Box";
var Flex = styled(Box).withConfig({
    componentId: "sc-4a04cfac-0"
})(_templateObject(), function(param) {
    var rowGap = param.rowGap;
    return rowGap ? "\n    row-gap: ".concat(rowGap, "\n  ") : "";
}, flexbox);
export default Flex;
