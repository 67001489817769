export const nftRewardConfig = [
  {
    id: 1,
    name: 'HEMATITE',
    price_usd: 500,
    bonus_opv: 500,
    dividend_usdt_percent: 0,
    reward_per_date: 0.05,
  },
  {
    id: 2,
    name: 'AMBER',
    price_usd: 1000,
    bonus_opv: 1000,
    dividend_usdt_percent: 0.001,
    reward_per_date: 0.1,
  },
  {
    id: 3,
    name: 'ZICRON',
    price_usd: 5000,
    bonus_opv: 5200,
    dividend_usdt_percent: 0,
    reward_per_date: 0.5,
  },
  {
    id: 4,
    name: 'PERIDOT',
    price_usd: 10000,
    bonus_opv: 11000,
    dividend_usdt_percent: 0,
    reward_per_date: 1,
  },
  {
    id: 5,
    name: 'TOURMALINE',
    price_usd: 20000,
    bonus_opv: 23000,
    dividend_usdt_percent: 0,
    reward_per_date: 2,
  },
  {
    id: 6,
    name: 'TOPAZ',
    price_usd: 50000,
    bonus_opv: 60000,
    dividend_usdt_percent: 0,
    reward_per_date: 5,
  },
  {
    id: 7,
    name: 'BERYL',
    price_usd: 100000,
    bonus_opv: 125000,
    dividend_usdt_percent: 0,
    reward_per_date: 10,
  },
  {
    id: 8,
    name: 'EMERALD',
    price_usd: 200000,
    bonus_opv: 260000,
    dividend_usdt_percent: 0,
    reward_per_date: 20,
  },
  {
    id: 9,
    name: 'SPINEL',
    price_usd: 500000,
    bonus_opv: 675000,
    dividend_usdt_percent: 0,
    reward_per_date: 50,
  },
  {
    id: 10,
    name: 'RUBY',
    price_usd: 1000000,
    bonus_opv: 1500000,
    dividend_usdt_percent: 1,
    reward_per_date: 100,
  },
]
