import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: block;\n  border: 0;\n  cursor: pointer;\n  width: 50px;\n  height: 50px;\n  padding: 4px 12px;\n  background: ",
        ";\n  box-shadow: ",
        ";\n  border-radius: 50%;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 34px;\n  left: 50%;\n  transform: translateX(-50%);\n\n  white-space: nowrap;\n  display: -webkit-box;\n  overflow: hidden;\n  user-select: none;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  -webkit-user-select: none;\n  -webkit-touch-callout: none;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { Text } from "../Text";
export var StyledBottomNavItem = styled.button.withConfig({
    componentId: "sc-51bd77b5-0"
})(_templateObject(), function(param) {
    var isActive = param.isActive;
    return isActive ? "#005E6B" : "transparent";
}, function(param) {
    var isActive = param.isActive;
    return isActive ? "0px 3px 4px rgba(0, 0, 0, 0.5)" : "unset";
});
export var StyledBottomNavText = styled(Text).withConfig({
    componentId: "sc-51bd77b5-1"
})(_templateObject1());
